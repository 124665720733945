const primary = [
  {
    path: '/features/user/list',
    name: 'feature-user-list',
    component: () => import('@/features/user/domain/pages/list/ContactListPage.vue'),
    meta: {
      layout: 'content',
      module: 'user',
      resource: 'Tenant',
    },
  },
  {
    path: '/features/user/reports/list',
    name: 'feature-user-reports-list',
    component: () => import('@/features/user/reports/report-list/ReportList.vue'),
    meta: {
      layout: 'content',
      module: 'user',
      resource: 'Tenant',
    },
  },
  {
    path: '/features/user/plugins/members',
    name: 'feature-user-member-list',
    component: () => import('@/features/user/plugins/member-list/Page.vue'),
    meta: {
      layout: 'blank',
      module: 'user',
      resource: 'Tenant',
    },
  },
]

export default primary
