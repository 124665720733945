const secondary = [
  // Team View
  {
    path: '/features/teams/:teamId/view',
    name: 'feature-team-view',
    component: () => import('@/features/team/domain/pages/view/Page.vue'),
    meta: {
      breadcrumb: [
        {
          name: 'Team List',
          exact: true,
          to: { name: 'feature-team-list' },
        },
        {
          name: 'Team View',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-team-list',
      module: 'team',
      resource: 'Tenant',
    },
  },

  // Add Team Member
  {
    path: '/features/teams/:teamId/members/add',
    name: 'feature-team-member-add',
    component: () => import('@/features/team/domain/pages/add-team-member/Page.vue'),
    meta: {
      breadcrumb: [
        {
          name: 'Team List',
          exact: true,
          to: { name: 'feature-team-list' },
        },
        {
          name: 'Team View',
          exact: true,
          to: { name: 'feature-team-view' },
          parameterMap: { teamId: 'teamId' },
        },
        {
          name: 'Add Team Member',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-team-list',
      module: 'team',
      resource: 'Tenant',
    },
  },

  // Team Report View
  {
    path: '/features/team/reports/view/:id',
    name: 'feature-team-report-view',
    component: () => import('@/features/team/reports/report-view/ReportView.vue'),
    meta: {
      breadcrumb: [
        {
          name: 'team Report List',
          exact: true,
          to: { name: 'feature-team-reports-list' },
        },
        {
          name: 'Report View',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-team-reports-list',
      module: 'team',
      resource: 'Tenant',
    },
  },

  // Compose Email View
  {
    path: '/features/team/email/compose',
    name: 'feature-team-compose-email',
    component: () => import('@/features/team/communication/pages/compose/ComposeEmail.vue'),
    props: route => ({ envelopeId: route.query.env }),
    meta: {
      breadcrumb: [
        {
          name: 'Team List',
          to: { name: 'feature-team-list' },
        },
        {
          name: 'Compose Email',
        },
      ],
      layout: 'content',
      navActiveLink: 'feature-team-list',
      module: 'team',
      resource: 'Tenant',
    },
  },

  // Team View Detail
  {
    path: '/features/team/plugin/teamDetail/:teamId',
    name: 'feature-team-detail-plugin',
    component: () => import('@/features/team/plugins/team-list/TeamViewDetail.vue'),
    meta: {
      breadcrumb: [
        {
          name: 'Team List',
          to: { name: 'feature-team-plugin-list' },
        },
        {
          name: 'Team Detail',
        },
      ],
      layout: 'blank',
      navActiveLink: 'feature-team-list',
      module: 'team',
      resource: 'Tenant',
    },
  },
]

export default secondary
