const primary = [
  // Core
  {
    path: '/features/communication/compose',
    name: 'feature-communication-compose',
    component: () => import('@/features/communication/domain/pages/compose/ComposeEmail.vue'),
    props: route => ({ communicationTemplateId: route.query.communicationTemplateId }),
    meta: {
      layout: 'content',
      module: 'communication',
      resource: 'Tenant',
    },
  },
  {
    path: '/features/communication/emailLogList',
    name: 'feature-communication-email-log-list',
    component: () => import('@/features/communication/domain/pages/emailLogList/EmailLogList.vue'),
    meta: {
      layout: 'content',
      module: 'communication',
      resource: 'Tenant',
    },
  },
  {
    path: '/features/communication/list',
    name: 'feature-communication-list',
    component: () => import('@/features/communication/domain/pages/list/CommunicationList.vue'),
    meta: {
      layout: 'content',
      module: 'communication',
      resource: 'Tenant',
    },
  },
  {
    path: '/features/communication/activity/list',
    name: 'feature-communication-activity-list',
    component: () => import('@/features/communication/activity/pages/list/ActivityList.vue'),
    props: route => ({ activitySource: route.query.activitySource, activityType: route.query.activityType }),
    meta: {
      layout: 'content',
      module: 'communication',
      resource: 'Tenant',
    },
  },

  // Tenant Settings

  // Host Settings
  {
    path: '/administration/communication/list',
    name: 'administration-communication-list',
    component: () => import('@/features/communication/settings/host/pages/list/HostCommunicationList.vue'),
    meta: {
      layout: 'content',
      module: 'communication',
      resource: 'Tenant',
    },
  },

  // Finance Host Settings
  {
    path: '/administration/Finance/Setting',
    name: 'feature-finance-host-setting',
    component: () => import('@/features/finance/hostSettings/FinanceSettingHost.vue'),
    meta: {
      layout: 'content',
      module: 'finance',
      resource: 'Tenant',
    },
  },

  // Error Log
  {
    path: '/administration/Monitor/LogError',
    name: 'feature-error-log-list',
    component: () => import('@/features/activity/domain/pages/list/ErrorLogList.vue'),
    meta: {
      layout: 'content',
      module: 'activity',
      resource: 'Tenant',
    },
  },

  // Monitoring Dashboard
  {
    path: '/administration/Monitor/Dashboard',
    name: 'feature-monitoring-chart',
    component: () => import('@/features/activity/charts/MonitoringChartDashboard.vue'),
    meta: {
      layout: 'content',
      module: 'activity',
      resource: 'Tenant',
    },
  },
]

export default primary
