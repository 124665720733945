import apiService from '@/core/api/apiService'

export default {
  // =========================================
  // reference data
  // =========================================
  fetchHostReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_MEMBERSHIP}/referencedata`, payload.queryParameters)
  },

  fetchTenantReferenceData(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/organizations/${payload.routeParameters.organizationId}/referencedata`,
      payload.queryParameters,
    )
  },

  // =========================================
  // contact list
  // =========================================
  fetchContactList(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/organizations/${payload.routeParameters.organizationId}/members`,
      payload.queryParameters,
    )
  },

  downloadContactList(ctx, payload) {
    return apiService.getBlob(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/organizations/${payload.routeParameters.organizationId}/members/download`,
      payload.queryParameters,
    )
  },

  // =========================================
  // contact
  // =========================================
  fetchContact(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/organizations/${payload.routeParameters.organizationId}/members/${payload.routeParameters.contactId}`,
      payload.queryParameters,
    )
  },

  fetchContactActivity(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/organizations/${payload.routeParameters.organizationId}/members/${payload.routeParameters.contactId}/activity`,
      payload.queryParameters,
    )
  },

  createContact(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/organizations/${payload.routeParameters.organizationId}/members`,
      payload.queryParameters,
      payload.dto,
    )
  },

  updateContact(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/organizations/${payload.routeParameters.organizationId}/members/${payload.routeParameters.contactId}`,
      payload.queryParameters,
      payload.dto,
    )
  },

  uploadContactProfilePicture(ctx, payload) {
    return apiService.postBlob(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/organizations/${payload.routeParameters.organizationId}/members/${payload.routeParameters.contactId}/picture`,
      payload.queryParameters,
      payload.dto,
    )
  },

  // =========================================
  // user contact list export options - TODO: move to user module
  // =========================================
  fetchUserContactListExportOptions(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_USER}/users/${payload.routeParameters.userId}/memberExportOptions`,
      payload.queryParameters,
    )
  },

  saveUserContactListExportOptions(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_USER}/users/${payload.routeParameters.userId}/memberExportOptions`,
      payload.queryParameters,
      payload.dto,
    )
  },

  // =========================================
  // invoice list export options
  // =========================================
  fetchInvoiceListExportOptions(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_USER}/users/${payload.routeParameters.userId}/invoiceExportOptions`,
      payload.queryParameters,
    )
  },

  saveInvoiceListExportOptions(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_USER}/users/${payload.routeParameters.userId}/invoiceExportOptions`,
      payload.queryParameters,
      payload.dto,
    )
  },

  // =========================================
  // charts
  // =========================================
  fetchMemberTypeSummary(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/organizations/${payload.routeParameters.organizationId}/charts/member-type-summary`,
      payload.queryParameters,
    )
  },

  fetchMemberStatusSummary(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/organizations/${payload.routeParameters.organizationId}/charts/member-status-summary`,
      payload.queryParameters,
    )
  },

  fetchMemberHistorySummary(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/organizations/${payload.routeParameters.organizationId}/charts/member-history-summary`,
      payload.queryParameters,
    )
  },

  // fetch Contacts search
  SearchContacts(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/organizations/${payload.routeParameters.organizationId}/searchmembers`,
      payload.queryParameters,
    )
  },

  fetchContactImis(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/organizations/${payload.routeParameters.organizationId}/imismembers/${payload.routeParameters.alternateId}`,
      payload.queryParameters,
    )
  },

  // =========================================
  // Member list
  // =========================================
  fetchMemberList(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/organizations/${payload.routeParameters.organizationId}/imssearchmembers`,
      payload.queryParameters,
    )
  },

  // =========================================
  // contact Plugin
  // =========================================
  fetchContactProfile(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_MEMBERSHIP}/member/${payload.routeParameters.alternateId}`,
      payload.queryParameters,
    )
  },
}
