class Contact {
  constructor() {
    this.id = '00000000-0000-0000-0000-000000000000'
    this.salutationCode = ''
    this.prefixCode = ''
    this.firstName = ''
    this.middleName = ''
    this.lastName = ''
    this.suffixCode = ''
    this.nickname = ''
    this.designationCode = ''
    this.title = ''
    this.companyName = ''
    this.notes = ''
    this.bio = ''
    this.emailAddress = ''
    this.phoneNumber = ''
    this.mobileNumber = ''
    this.faxNumber = ''
    this.address1 = ''
    this.address2 = ''
    this.address3 = ''
    this.city = ''
    this.stateProvinceCode = ''
    this.zipCode = ''
    this.countryCode = ''
    this.profilePictureUrl = ''
    this.assistantName = ''
    this.assistantEmailAddress = ''
    this.assistantPhoneNumber = ''
    this.specialties = []
    this.barAdmissions = []
    this.membershipContext = {
      organizationId: '',
      memberTypeCode: '',
      memberSubTypeCode: '',
      memberStatusCode: '',
      beginDate: '',
      endDate: '',
    }
  }
}

export default Contact
