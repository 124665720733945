const primary = [
  {
    path: '/features/team/list',
    name: 'feature-team-list',
    component: () => import('@/features/team/domain/pages/list/Page.vue'),
    meta: {
      layout: 'content',
      module: 'team',
      resource: 'Tenant',
    },
  },
  {
    path: '/features/team/reports/list',
    name: 'feature-team-reports-list',
    component: () => import('@/features/team/reports/report-list/ReportList.vue'),
    meta: {
      layout: 'content',
      module: 'team',
      resource: 'Tenant',
    },
  },
  {
    path: '/features/team/plugin/team-list',
    name: 'feature-team-plugin-list',
    component: () => import('@/features/team/plugins/team-list/Page.vue'),
    meta: {
      layout: 'blank',
      module: 'team',
      resource: 'Tenant',
    },
  },
]

export default primary
