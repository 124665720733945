import apiService from '@/core/api/apiService'
import store from '@/store'
import axios from '@axios'

export default {
  // =========================================
  // reference data
  // =========================================
  fetchHostReferenceData(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_FINANCE}/referencedata`, payload.queryParameters)
  },

  fetchTenantReferenceData(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/referencedata`,
      payload.queryParameters,
    )
  },

  // =========================================
  // invoice settings (Should be Tenant Settings)
  // =========================================
  fetchInvoiceSettings(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices/settings`,
      payload.queryParameters,
    )
  },

  saveInvoicePaymentSettings(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices/settings/payments`,
      payload.queryParameters,
      payload.dto,
    )
  },

  // =========================================
  // products
  // =========================================
  fetchProducts(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/products`,
      payload.queryParameters,
    )
  },

  fetchProduct(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/products/${payload.routeParameters.productId}`,
      payload.queryParameters,
    )
  },

  createProduct(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/products`,
      payload.queryParameters,
      payload.dto,
    )
  },

  // getNotesForStepDescriptipn(ctx, payload) {
  //   axios({
  //     url: '/aic/api/graphql',
  //     method: 'post',
  //     data: {
  //       query: `
  //       query innManagementSystemContent{
  //         innManagementSystemContent(where: {alias: {alias_starts_with: "${payload.data}"}}) {
  //           createdUtc
  //           displayText
  //           modifiedUtc
  //           publishedUtc
  //           markdownBody {
  //             html
  //             markdown
  //           }
  //           title
  //         }
  //       }`,
  //     },
  //   }).then(result => {
  //     ctx.commit('SET_NOTE_DATA', { data: result.data.innManagementSystemContent, step: params.step })
  //   })
  // },

  updateProduct(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/products/${payload.routeParameters.productId}`,
      payload.queryParameters,
      payload.dto,
    )
  },

  deleteProduct(ctx, payload) {
    return apiService.delete(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/products/${payload.routeParameters.productId}`,
      payload.queryParameters,
    )
  },

  archiveProduct(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/products/${payload.routeParameters.productId}/archive`,
      payload.queryParameters,
      payload.dto,
    )
  },

  restoreProduct(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/products/${payload.routeParameters.productId}/restore`,
      payload.queryParameters,
      payload.dto,
    )
  },

  // =========================================
  // invoices
  // =========================================
  fetchInvoiceFilterOptions(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices/filterOptions`,
      payload.queryParameters,
    )
  },

  fetchInvoices(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices`,
      payload.queryParameters,
    )
  },
  fetchInvoicesByEmailJobId(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/emailJobId/${payload.routeParameters.emailJobId}/invoices`,
      payload.queryParameters,
    )
  },

  // =========================================
  // invoice list download
  // =========================================

  downloadInvoiceList(ctx, payload) {
    return apiService.getBlob(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices/download`,
      payload.queryParameters,
    )
  },

  // =========================================
  // invoice
  // =========================================
  fetchInvoice(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices/${payload.routeParameters.invoiceId}`,
      payload.queryParameters,
    )
  },

  fetchInvoiceHistory(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices/${payload.routeParameters.invoiceId}/history`,
      payload.queryParameters,
    )
  },

  createInvoices(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices`,
      payload.queryParameters,
      payload.dto,
    )
  },

  updateInvoice(ctx, payload) {
    return apiService.put(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices/${payload.routeParameters.invoiceId}`,
      payload.queryParameters,
      payload.dto,
    )
  },

  payInvoicesByWriteOff(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices/payments/writeoff`,
      payload.queryParameters,
      payload.dto,
    )
  },

  payInvoicesByCash(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices/payments/cash`,
      payload.queryParameters,
      payload.dto,
    )
  },

  payInvoicesByCheck(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices/payments/check`,
      payload.queryParameters,
      payload.dto,
    )
  },

  payInvoicesByPeerToPeer(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices/payments/p2p`,
      payload.queryParameters,
      payload.dto,
    )
  },

  payInvoicesByCreditCard(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices/payments/cc`,
      payload.queryParameters,
      payload.dto,
    )
  },

  deleteSingleInvoice(ctx, payload) {
    return apiService.delete(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices/${payload.routeParameters.invoiceId}`,
      payload.queryParameters,
    )
  },

  deleteMultipleInvoices(ctx, payload) {
    return apiService.delete(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices`,
      payload.queryParameters,
      payload.dto,
    )
  },

  downloadInvoice(ctx, payload) {
    store.commit('app/INCREMENT_LOADING')

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.urlParams.organizationId}/invoices/${payload.urlParams.invoiceId}/download`,
          {
            responseType: 'blob',
            params: payload.queryParams,
          },
        )
        .then(response => {
          resolve(response)
          store.commit('app/DECREMENT_LOADING')
        })
        .catch(error => {
          reject(error)
          store.commit('app/DECREMENT_LOADING')
        })
    })
  },

  createInvoiceItem(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/invoices/${payload.routeParameters.invoiceId}/items`,
      payload.queryParameters,
      payload.dto,
    )
  },

  deleteInvoiceItem(ctx, payload) {
    return apiService.delete(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}//invoices/${payload.routeParameters.invoiceId}/items`,
      payload.queryParameters,
    )
  },

  // =========================================
  // charts
  // =========================================
  fetchPaymentTypeSummary(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/finance/charts/payment-type-summary`,
      payload.queryParameters,
    )
  },

  fetchInvoiceStatusSummary(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/finance/charts/invoice-status-summary`,
      payload.queryParameters,
    )
  },

  fetchIncomeSummary(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/finance/charts/income-summary`,
      payload.queryParameters,
    )
  },

  // =========================================
  // paypal
  // =========================================
  fetchPayPalClientContext(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/payments/cc/paypal/clientContext`,
      payload.queryParameters,
    )
  },

  createPayPalOrder(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/payments/cc/paypal/orders`,
      payload.queryParameters,
      payload.dto,
    )
  },

  capturePayPalOrder(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/payments/cc/paypal/orders/${payload.routeParameters.orderId}/capture`,
      payload.queryParameters,
      payload.dto,
    )
  },

  // =========================================
  // bluepay
  // =========================================
  createBluePayOrder(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/payments/cc/bluepay/orders`,
      payload.queryParameters,
      payload.dto,
    )
  },

  // =========================================
  // clover
  // =========================================
  createCloverOrder(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/payments/cc/clover/orders`,
      payload.queryParameters,
      payload.dto,
    )
  },

  // =========================================
  // payments
  // =========================================
  fetchOrganizationPaymentHistory(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/payments`,
      payload.queryParameters,
    )
  },
  fetchContactPaymentHistory(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/contacts/${payload.routeParameters.contactId}/payments`,
      payload.queryParameters,
    )
  },

  fetchPayment(ctx, payload) {
    return apiService.get(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/payments/${payload.routeParameters.paymentId}`,
      payload.queryParameters,
    )
  },

  // Host Setting
  fetchFinanceHostSettings(ctx, payload) {
    return apiService.get(`${process.env.VUE_APP_API_PATH_FINANCE}/hostSettings`)
  },

  saveFinanceHostSettings(ctx, payload) {
    return apiService.post(
      `${process.env.VUE_APP_API_PATH_FINANCE}/organizations/${payload.routeParameters.organizationId}/hostSettings`,
      payload.queryParameters,
      payload.dto,
    )
  },
}
