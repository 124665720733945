const primary = [
  {
    path: '/features/committee/list',
    name: 'feature-committee-list',
    component: () => import('@/features/committee/domain/pages/list/Page.vue'),
    meta: {
      layout: 'content',
      module: 'committee',
      resource: 'Tenant',
    },
  },
  {
    path: '/features/committee/reports/list',
    name: 'feature-committee-reports-list',
    component: () => import('@/features/committee/reports/report-list/ReportList.vue'),
    meta: {
      layout: 'content',
      module: 'committee',
      resource: 'Tenant',
    },
  },
  {
    path: '/features/committee/plugins/officers',
    name: 'feature-committee-officer-list',
    component: () => import('@/features/committee/pulgins/officers-list/Page.vue'),
    meta: {
      layout: 'blank',
      module: 'committee',
      resource: 'Tenant',
    },
  },
  {
    path: '/features/committee/plugins/committee',
    name: 'feature-committee-list-plugin',
    component: () => import('@/features/committee/pulgins/committee-list/Page.vue'),
    meta: {
      layout: 'blank',
      module: 'committee',
      resource: 'Tenant',
    },
  },
]

export default primary
